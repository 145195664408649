.App {
  text-align: center;
  

}


@font-face {
  font-family: 'logo';
  src: url("../public/Broetown Signature.ttf");
}
@font-face {
  font-family: 'drexs';
  src: url("../public/duepuntozero_extralight.otf");
}


.counter{
font-family: drexs;

}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.unten{
  top: -350px;
  left: 0;
  margin-bottom: 1%;
  display: flex;
  justify-content:end;
 
}

@keyframes fadin {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.jumbotron  {

/*background-image: url('../public/power.jpeg');*/ 

background-size: cover;
font-family: logo;
  min-height: auto;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: center;
padding-top: 20px;
z-index: -1 ;



}

.banner{
  position: absolute;
  
  left:0;
  
  animation: fadin 1s ;
  z-index: 200  ;
  



}

@keyframes fadin {
  from {
    opacity: 0;

 
  }
  to {
    opacity: 1;


  }
  
}
.fader {
  position: absolute;


  animation: fadin 2s ;
  animation-timing-function: ease-in-out;
  

}
.fonter{
  font-family: logo;
 


}

.transparent{
opacity: 10;
filter: brightness(100%)  grayscale(100%)  blur(100px);

transition: all 1s ease-in-out;

z-index: -1;
}

.searchfield{
  position: absolute;
  top: 0px;
  padding: 5px;
  left: 0;
  width: 100%;
 
}
.st{
fill: rgb(212, 1, 1) ;
}
.sizer{

  width: 150px;
  justify-content: end;

fill: azure;
scale: .5;
}

.scrollbar-hide::-webkit-scrollbar {
    display: grid;
    width: 1px; 
    margin: 4px ;
    padding: 4px;
     /* Safari and Chrome */
}
.scrollbar-hide::-webkit-scrollbar-thumb {
    background-color: rgb(0, 0, 0);
    margin: 4px;
    width: 1px; 
     /* Safari and Chrome */
}
/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style:none ;  /* IE and Edge */
    scrollbar-width: 2px;  /* Firefox */

}
.first::before{
  content: "-";
font-size: 3em;
color: white  ;


}

.roundme{
  border-radius: 25px;
}
 .image-container {
width: 300px; /* Feste Breite des Containers */
            width: 250px; /* Feste Breite des Containers */
            height: 250px; /* Feste Höhe des Containers */

             /* Rahmen des Containers */
            position: relative; /* Setze Position relativ für absolute Positionierung des Bildes */
            overflow: hidden;
             border-bottom:orangered 20px solid;
             border-right: orangered 10px solid;
             
    

             /* Verstecke Überlauf auf anderen Seiten */
           
       
  border-radius: 50%;
    
           
        }
        .bager-container {
            position: relative; /* Setze Position relativ für absolute Positionierung des Bildes */
         /* Setze Abstand von links */
            width: 200px; /* Setze Breite des Bildes */
            height: 200px; 
            left:-50px;/* Setze Höhe des Bildes */
            border-radius: 50%; /* Setze Rundung des Bildes */
          

           
            border-bottom:orangered 30px solid;
            border-right: orangered 10px solid;
            
          
            /* Setze Bildposition */
        }
       .image-bager {
       /* Setze Position relativ für absolute Positionierung des Bildes */
             /* Setze Position absolut für absolute Positionierung des Bildes */
           position: absolute;
        
             top: -50px; /* Setze Abstand von oben */
            left: -20px;
            object-fit:cover;
            width: 250px; /* Setze Breite des Bildes */
            height: 250px; /* Setze Höhe des Bildes */
            
     
       
          /* Setze Bildposition */
        }
        .valve-container {
            position: relative; /* Setze Position relativ für absolute Positionierung des Bildes */
            width: 200px; /* Setze Breite des Bildes */
            height: 200px; /* Setze Höhe des Bildes */
            border-radius: 50%; /* Setze Rundung des Bildes */
            border-bottom:orangered 20px solid; /* Setze Rahmen des Containers */
            border-left: orangered 20px solid;
          
            overflow: hidden;
            left: 50px; /* Verstecke Überlauf auf anderen Seiten */
        }
        .valve-image {
            position: absolute; /* Setze Position absolut für absolute Positionierung des Bildes */
            top: 0px; /* Setze Abstand von oben */
            left: 0px; /* Setze Abstand von links */
            width: 100%; /* Setze Breite des Bildes */
            height: 100%; /* Setze Höhe des Bildes */
            object-fit: cover; /* Setze Bildposition */
        }
        
          .upperpupple-left {
            position: relative; /* Setze Position relativ für absolute Positionierung des Bildes */
            width: 150px; /* Setze Breite des Bildes */
            height: 150px; /* Setze Höhe des Bildes */
            border-radius: 50%; /* Setze Rundung des Bildes */
            border-bottom: orangered 10px solid;
            border-right: orangered 10px solid; 
            /* Setze Rahmen des Containers */
            overflow: hidden;
            left: -100px;
            top: -0px; /* Verstecke Überlauf auf anderen Seiten */
        }
       .pubble{
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: azure;
      
        top: 0px;
        
       }
       
       .slide {
        min-height: 500px;
        max-height: 600px;
        padding: 0;
        margin: 0;
       }

       @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
        
       }

       .rotate{
        animation: rotate 10s linear infinite;
       }
        .rotate-r{
          animation: rotate 10s linear infinite reverse; 
        }

        .logofont{
          font-family: logo;
        }

        .bogen{
          position: relative  ;
          bottom: 220px;
          height:400px;
          width: 400px;
          border-radius: 50%;
        }
    
        
         
          
         
         
         
      

          

        
        .doco-container{
        
          position:absolute  ;
          display: flex;
          justify-content: center;
         
     
    
     
          rotate: 45deg;
 
         
          width: 2000px;
          height: 2000px;

          align-items: center;
          /* border-bottom-left-radius: 50%; */
      bottom:-1000px;
      right: -1200px;
      
      
      
      
     
          
        
          
        } 

            .deco{
              
          position: fixed  ;
          display: flex;
          justify-content: center;
       
          color: white;
        
          z-index: 100;
          padding-bottom: 500px;
          padding-right: 1200px;

     
         
         height: 100%;
         rotate: -45deg;
      
            }


            .cookies{
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              top: 25%;
              
              z-index: 200;
              margin: auto;
              vertical-align: middle;
              
       

             
    
            }
            .cookie{
              position: fixed;
              top: 0;
              left: 0;
              height: 100%;
              z-index: 200;
      
       
              background-color: rgba(0, 0, 0, 0.9);
            }

.svg-container {

    width: 100%;
    height: 100%;
     /* Adjust this to the desired height */
    display: flex;
    justify-content: center;
    align-items: center;
}

.responsive-svg {
    width: 100%;
    height: 100%;
}
.slogan{

  background-image: url("../public/img/AdobeStock_263795896.jpeg");
  background-size: cover;

 
  

}
.bgimage{
  background-image: url("../public/img/engs2.png");
  background-position: 100%;
  background-size:cover;
  background-repeat: no-repeat;
  align-items: flex-start;
}
.bgservice_image{
  background-image: url("../public/img/AdobeStock_617011202.jpeg");
  background-position: 0;
  background-size:cover;
  background-repeat: no-repeat;
  align-items: flex-start;
}